import AdministratorStore from './AdministratorStore';
import ApplicationStore from './ApplicationStore';
import AuthStore from './AuthStore';
import AutomakerStore from './AutomakerStore';
import BrandStore from './BrandStore';
import BannerStore from './BannerStore';
import CategoryStore from './CategoryStore';
import ComboStore from './ComboStore';
import ComparativeStore from './ComparativeStore';
import CouponStore from './CouponStore';
import CustomerStore from './CustomerStore';
import CustomerTypeStore from './CustomerTypeStore';
import HomeStore from './HomeStore';
import InstitutionalStore from './InstitutionalStore';
import KitStore from './KitStore';
import MenuStore from './MenuStore';
import MerchantStore from './MerchantStore';
import NotificationStore from './NotificationStore';
import OrderStore from './OrderStore';
import PreOrderStore from './PreOrderStore';
import ProductStore from './ProductStore';
import PropertiesStore from './PropertiesStore';
import ShippingClassStore from './ShippingClassStore';
import ShippingServiceStore from './ShippingServiceStore';
import SupplierStore from './SupplierStore';
import UsersStore from './UsersStore';
import ZipCodeRangeStore from './ZipCodeRangeStore';
import NewsLetterStore from './NewsLetterStore';
import AuctionStore from './AuctionStore';
import LandingPageStore from './LandingPageStore';
/**
 * Rootstore guarda a instancia das stores.
 * Assim a comunicação das stores é feita atraves da rootStore.
 *
 * https://mobx.js.org/best/store.html
 */

class RootStore {
  _mobxStores = null;

  constructor(
    serializedStores = {
      usersStore: {},
      customerTypeStore: {},
      authStore: {},
      bannerStore: {},
      categoryStore: {},
      menuStore: {},
      comparativeStore: {},
      productStore: {},
      propertiesStore: {},
      merchantStore: {},
      supplierStore: {},
      automakerStore: {},
      orderStore: {},
      notificationStore: {},
      administratorStore: {},
      customerStore: {},
      preOrderStore: {},
      shippingServiceStore: {},
      shippingClassStore: {},
      institutionalStore: {},
      applicationStore: {},
      comboStore: {},
      kitStore: {},
      couponStore: {},
      zipCodeRangeStore: {},
      homeStore: {},
      brandStore: {},
      newsLetterStore: {},
      auctionStore: {},
      landingPageStore: {},
    }
  ) {
    if (this._mobxStores === null) {
      this.usersStore = new UsersStore(this, {
        ...serializedStores.usersStore,
      });
      this.customerTypeStore = new CustomerTypeStore(this, {
        ...serializedStores.customerTypeStore,
      });
      this.authStore = new AuthStore(this, {
        ...serializedStores.authStore,
      });
      this.bannerStore = new BannerStore(this, {
        ...serializedStores.bannerStore,
      });
      this.categoryStore = new CategoryStore(this, {
        ...serializedStores.categoryStore,
      });
      this.menuStore = new MenuStore(this, {
        ...serializedStores.menuStore,
      });
      this.productStore = new ProductStore(this, {
        ...serializedStores.productStore,
      });
      this.propertiesStore = new PropertiesStore(this, {
        ...serializedStores.propertiesStore,
      });
      this.merchantStore = new MerchantStore(this, {
        ...serializedStores.merchantStore,
      });
      this.supplierStore = new SupplierStore(this, {
        ...serializedStores.supplierStore,
      });
      this.automakerStore = new AutomakerStore(this, {
        ...serializedStores.automakerStore,
      });
      this.orderStore = new OrderStore(this, {
        ...serializedStores.orderStore,
      });
      this.notificationStore = new NotificationStore(this, {
        ...serializedStores.notificationStore,
      });
      this.administratorStore = new AdministratorStore(this, {
        ...serializedStores.administratorStore,
      });
      this.customerStore = new CustomerStore(this, {
        ...serializedStores.customerStore,
      });
      this.preOrderStore = new PreOrderStore(this, {
        ...serializedStores.preOrderStore,
      });
      this.comparativeStore = new ComparativeStore(this, {
        ...serializedStores.comparativeStore,
      });
      this.shippingServiceStore = new ShippingServiceStore(this, {
        ...serializedStores.shippingServiceStore,
      });
      this.shippingClassStore = new ShippingClassStore(this, {
        ...serializedStores.shippingClassStore,
      });
      this.institutionalStore = new InstitutionalStore(this, {
        ...serializedStores.institutionalStore,
      });
      this.applicationStore = new ApplicationStore(this, {
        ...serializedStores.applicationStore,
      });
      this.comboStore = new ComboStore(this, {
        ...serializedStores.comboStore,
      });
      this.kitStore = new KitStore(this, {
        ...serializedStores.kitStore,
      });
      this.couponStore = new CouponStore(this, {
        ...serializedStores.couponStore,
      });
      this.zipCodeRangeStore = new ZipCodeRangeStore(this, {
        ...serializedStores.zipCodeRangeStore,
      });
      this.homeStore = new HomeStore(this, {
        ...serializedStores.homeStore,
      });
      this.brandStore = new BrandStore(this, {
        ...serializedStores.brandStore,
      });
      this.newsLetterStore = new NewsLetterStore(this, {
        ...serializedStores.newsLetterStore,
      });
      this.auctionStore = new AuctionStore(this, {
        ...serializedStores.auctionStore,
      });
      this.landingPageStore = new LandingPageStore(this, {
        ...serializedStores.landingPageStore,
      });
    }
  }

  get mobxStores() {
    return (this._mobxStores = {
      usersStore: this.usersStore,
      customerTypeStore: this.customerTypeStore,
      authStore: this.authStore,
      bannerStore: this.bannerStore,
      categoryStore: this.categoryStore,
      menuStore: this.menuStore,
      productStore: this.productStore,
      propertiesStore: this.propertiesStore,
      merchantStore: this.merchantStore,
      supplierStore: this.supplierStore,
      automakerStore: this.automakerStore,
      orderStore: this.orderStore,
      notificationStore: this.notificationStore,
      administratorStore: this.administratorStore,
      customerStore: this.customerStore,
      preOrderStore: this.preOrderStore,
      comparativeStore: this.comparativeStore,
      shippingServiceStore: this.shippingServiceStore,
      shippingClassStore: this.shippingClassStore,
      institutionalStore: this.institutionalStore,
      applicationStore: this.applicationStore,
      comboStore: this.comboStore,
      kitStore: this.kitStore,
      couponStore: this.couponStore,
      zipCodeRangeStore: this.zipCodeRangeStore,
      homeStore: this.homeStore,
      brandStore: this.brandStore,
      newsLetterStore: this.newsLetterStore,
      auctionStore: this.auctionStore,
      landingPageStore: this.landingPageStore,
    });
  }
}

let store = null;

export function initializeStores(props) {
  if (store === null) {
    store = new RootStore(props);
  }
  return store;
}

export default RootStore;
