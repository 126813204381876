import axios from 'axios';
import BaseAPI, { URLS, baseURL } from './BaseAPI';
import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';

class LandingPageAPI {
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  static async createLandingPage(data) {
    try {
      const url = `${URLS.LANDINGPAGE}`;
      const response = await BaseAPI.post(url, data);
      if (response.status === 201) return response.data;
      return { error: 'Falha ao criar landing page.' };
    } catch (e) {
      return this._exception(e, 'Falha ao criar landing page.');
    }
  }

  static async updateLandingPage(uuid, data) {
    try {
      const url = `${URLS.LANDINGPAGE}/${uuid}`;
      const response = await BaseAPI.put(url, data);
      
      if (response.status === 200) return response.data;
      return { error: 'Falha ao atualizar landing page.' };
    } catch (e) {
      return this._exception(e, 'Falha ao atualizar landing page.');
    }
  }

  static async getAllLandingPages(params = {}) {
    try {
      const url = `${URLS.LANDINGPAGE}/all`;
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar landing page.' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar landing page.');
    }
  }

  static async getLandingPageByUuid(uuid) {
    try {
      const url = `${URLS.LANDINGPAGE}/${uuid}`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar landing page.' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar landing page.');
    }
  }

  static async getLandingPageBySlug(slug) {
    try {
      const url = `${URLS.LANDINGPAGE}/slug/${slug}`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar landing page.' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar landing page.');
    }
  }

  static async createComponent(data) {
    try {
      const url = `${URLS.LANDINGPAGE}/component`;
      const response = await BaseAPI.post(url, data);
      if (response.status === 201) return response.data;
      return { error: 'Falha ao criar componente.' };
    } catch (e) {
      return this._exception(e, 'Falha ao criar componente.');
    }
  }

  static async getComponentByUuid(uuid) {
    try {
      const url = `${URLS.LANDINGPAGE}/component/${uuid}`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar componente.' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar componente.');
    }
  }

  static async deleteComponent(uuid) {
    try {
      const url = `${URLS.LANDINGPAGE}/component/${uuid}`;
      const response = await BaseAPI.delete(url);
      if (response.status === 204) return response.data;
      return { error: 'Falha ao deletar componente.' };
    } catch (e) {
      return this._exception(e, 'Falha ao deletar componente.');
    }
  }

  static async deleteFaqComponent(uuid) {
    try {
      const url = `${URLS.LANDINGPAGE}/faq/${uuid}`;
      const response = await BaseAPI.delete(url);
      if (response.status === 204) return response.data;
      return { error: 'Falha ao deletar componente.' };
    } catch (e) {
      return this._exception(e, 'Falha ao deletar componente.');
    }
  }

  static async uploadFile(file) {

    try {
      const url = `${URLS.LANDINGPAGE}/upload/file`;
      const response = await this._uploadFiles(url, file);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao enviar arquivo.' };
    } catch (e) {
      return this._exception(e, 'Falha ao enviar arquivo.');
    }
  }

  static async _uploadFiles(endpoint, file) {
    const form = new FormData();
    
    form.append('file', file, file.name);
    form.append('metaTags', JSON.stringify(file.metaTags));
    form.append('name', file.name);

    const config = getConfig(file);
    return await axios.post(`${baseURL()}${endpoint}`, form, config);
  }

}

const getConfig = (uploadItem) => ({
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${StorageUtil.getItem(KEYS.TOKEN_KEY)}`,
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) =>
      (uploadItem.percentCompleted = parseInt(
        Math.floor((progressEvent.loaded * 100) / progressEvent.total)
      )),
  });

export default LandingPageAPI;