import BaseModel from './BaseModel';
import ComponentLPModel from './ComponentLPModel';

class LandingPageModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.uuid = data.uuid;
    this.active = data.active;
    this.created = data.created;
    this.deleted = data.deleted;
    this.description = data.description;
    this.modified = data.modified;
    this.title = data.title;
    this.name = data.name;
    this.slug = data.slug;
    this.type = data.type;
    this.bannerPrincipal = data.bannerPrincipal;
    this.content = data.content && data.content.map((component) => new ComponentLPModel(component));

    this.url = `https://www.sportbay.com.br/${data.type || ''}/${data.slug || ''}`;
  }
}

export default LandingPageModel;
